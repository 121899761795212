import { Row, Titles } from "./_index";

export const DaTaTable: React.FC<any> = ({ data }: any) => {
  return (
    <section className="tables">
      <div className="big_box">
        <div className="table">
          <table>
            <tbody>
              <tr>
                {Titles.map(({ title }: any, index: any) => (
                  <th key={index}>
                    {title}
                  </th>
                ))}
              </tr>
              {data.map((details: any, index: any) => (
                <Row key={index} values={[details, index]} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};
