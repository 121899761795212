export const formFields = [
  {
    name: "name",
    label: "Name",
    type: "text",
    placeholder: "Client Name",
  },
  {
    name: "company",
    label: "Company",
    type: "text",
    placeholder: "Client Company",
  },

];
