export const Loader: React.FC<any> = () => {
  return (
    <div className="loader_container_model_1">
      <div className="loader_model_1">
        <div className="fb_loader"></div>
      </div>
      <div className="loader_model_2">
        <div className="fb_loader"></div>
      </div>
      <div className="loader_model_3">
        <div className="fb_loader"></div>
      </div>
      <div className="loader_model_2">
        <div className="fb_loader"></div>
      </div>
    </div>
  );
};
