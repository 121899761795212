import React, { useCallback, useEffect, useRef, useState } from "react";
import { ProjectService } from "../../../services/_index";
import "../../../style/tables.css";
import { Add, Update, Titles, Row } from "./components/_index";
import {
  ConfirmDeleteItem,
  SearchBar,
  SortIndicator,
  Table,
} from "../../../components/_index";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { useGlobalContext } from "../../../context/globalContext";

const ProjectList: React.FC = () => {
  const [changeScroll, setChangeScroll] = useState(false);
  const [showAddSection, setShowAddSection] = useState(false);
  const [showUpdateSection, setShowUpdateSection] = useState(false);
  const [items, setItems] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState<any>(null);
  const [desc_asc, setDesc_asc] = useState(false);
  const [orderQuery, setOrderQuery] = useState<any>("order");
  const [iSsortSymNum, setISsortSymNum] = useState(true);
  const [myUpdatingItem, setMyUpdatingItem] = useState(null);
  const [fetchData, setFetchData] = useState(false);
  const [checkboxArr, setCheckboxArr] = useState<any>([]);
  const [sortName, setSortName] = useState("Order");
  const [isLoad, setIsLoad] = useState(true);
  const isInitialMount = useRef(true);
  const { width } = useWindowSize();
  const { setMyPath } = useGlobalContext();


  const handleScrollChange = () => {
    const myContainer: HTMLHtmlElement | null =
      document.querySelector(".container");
    const myHtml = document.querySelector("html");
    const myContainerPageTitle: HTMLHtmlElement | null = document.querySelector(
      ".container_page_title"
    );

    if (myContainer && myHtml && myContainerPageTitle) {
      if (changeScroll) {
        myContainer.style.zIndex = "9";
        myHtml.style.overflow = "hidden";
        myContainerPageTitle.style.opacity = "0";
      } else {
        myContainer.style.zIndex = "1";
        myHtml.style.overflow = "auto";
        if (width < 768) myContainerPageTitle.style.opacity = "1";
      }
    }
  };

  useEffect(() => {
    setMyPath("Projects");
    document.title = "Projects";
  }, [setMyPath]);
  
  useEffect(() => {
    handleScrollChange();
  }, [changeScroll]);

  const fetchDataCallback = useCallback(
    (options = {}) => {
      ProjectService.fetchProjects(setItems, setIsLoad, {
        withoutLoading: true,
        search: searchQuery,
        order_by: orderQuery,
        order_direction: desc_asc ? "desc" : "asc",
        ...options,
      });
    },
    [searchQuery, orderQuery, desc_asc]
  );

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      fetchDataCallback();
    } else {
      const debounceTimer = setTimeout(() => {
        fetchDataCallback();
      }, 300);

      return () => clearTimeout(debounceTimer);
    }
  }, [fetchData, searchQuery, orderQuery, desc_asc]);

  const hiddenAddSection = (fetchData = false) => {
    setChangeScroll(false);
    setShowAddSection(false);
    fetchData && setFetchData((prev) => !prev);
  };

  const ShowAddSection = () => {
    setChangeScroll(true);
    setShowAddSection(true);
  };

  const ShowUpdateSection = (itemId: any) => {
    const myItem = items.data.find((item: any) => item?.id === itemId);
    if (myItem) {
      setMyUpdatingItem(myItem);
      setChangeScroll(true);
      setShowUpdateSection(true);
    }
  };

  const hiddenUpdateSection = (fetchData = false) => {
    setChangeScroll(false);
    setShowUpdateSection(false);
    setMyUpdatingItem(null);
    fetchData && setFetchData((prev) => !prev);
  };



  return (
    <section className="tables">
      <div className="big_box">
        <div className="options">
          <div className="left">
            <div className="sortBox">{sortName}</div>
            <SortIndicator desc_asc={desc_asc} iSsortSymNum={iSsortSymNum} />
          </div>
          <div className="center buttons">
            <button className="btn_prem add_btn" onClick={ShowAddSection}>
              Add
            </button>
            <button
              // @ts-ignore
              className={`dlt_btn ${!checkboxArr.length > 0 && "dlt_btn_hide"}`}
              onClick={() =>
                ConfirmDeleteItem(
                  checkboxArr,
                  setFetchData,
                  setCheckboxArr,
                  "project"
                )
              }
            >
              Delete
            </button>
          </div>
          <SearchBar
            myProps={{
              searchQuery,
              setSearchQuery,
            }}
          />
        </div>
        <Table
          myProps={{
            isLoad,
            items,
            ShowUpdateSection,
            checkboxArr,
            setCheckboxArr,
            setOrderQuery,
            setDesc_asc,
            setISsortSymNum,
            setFetchData,
            setSortName,
            Titles,
            Row,
          }}
        />
      </div>
      {showAddSection && <Add hiddenAddSection={hiddenAddSection} />}
      {showUpdateSection && (
        <Update
          hiddenUpdateSection={hiddenUpdateSection}
          details={myUpdatingItem}
        />
      )}
    </section>
  );
};

export default ProjectList;
