import React from "react";
import { PiUsersThreeFill } from "react-icons/pi";
import { HiMiniCodeBracketSquare } from "react-icons/hi2";
import { BiSolidCommentDetail } from "react-icons/bi";
import { Link } from "react-router-dom";

const DataBoxes: React.FC <any>= ({data}) => {
  const {projects, employees, testimonials} = data;
  // options: ["projects", "tags", "employees", "testimonials"],
  return (

    <div className={"general_data"}>
      <>
        <Link to="/tables/projects" className={"data_box"}>
          <div className={"icon"}>
            <HiMiniCodeBracketSquare />
          </div>
          <div className={"data"}>
            <div className={"info"}>
              <div className={"info_title"}>
                Projects
              </div>
              <div className={"value"}>
                {projects}
              </div>
            </div>
          </div>
        </Link>

        <Link to="/tables/employees" className={"data_box"}>
          <div className={"icon"}>
            <PiUsersThreeFill />
          </div>
          <div className={"data"}>
            <div className={"info"}>
              <div className={"info_title"}>
                Employees
              </div>
              <div className={"value"}>
                {employees}
              </div>
            </div>
          </div>
        </Link>
        <Link to="/tables/testimonials" className={"data_box"}>
          <div className={"icon"}>
            <BiSolidCommentDetail />
          </div>
          <div className={"data"}>
            <div className={"info"}>
              <div className={"info_title"}>
                testimonials
              </div>
              <div className={"value"}>
                {testimonials}
              </div>
            </div>
          </div>
        </Link>
      </>    
    </div>
  );
};

export default DataBoxes;
