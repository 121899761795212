"use client";

import { createContext, useContext, useState } from "react";
const GlobalContext = createContext<any>(undefined);

export const GlobalProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [myPath, setMyPath] = useState("Home");

  return (
    <GlobalContext.Provider
      value={{
        myPath,
        setMyPath,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error("useGlobalContext must be used within an AuthProvider");
  }
  return context;
};
