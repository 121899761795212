const resetFormData = (
  data: any,
  reset: any,
) => {
  reset({
    ...data,
  });


};

export default resetFormData;
