export const Titles = [
  {
    title: "ID",
    isNumber: true,
    orderFunctionality: true,
    OrderTitle: "Time",
    dbName: "created_at",
  },
  {
    title: "Image",
    orderFunctionality: false,
  },
  {
    title: "LinkedIn",
    orderFunctionality: false,
  },
  {
    title: "Name",
    isNumber: false,
    orderFunctionality: true,
    OrderTitle: "Name",
    dbName: "name",
  },
  {
    title: "Position",
    isNumber: false,
    orderFunctionality: true,
    OrderTitle: "Position",
    dbName: "position",
  },
  {
    title: "Bio",
    orderFunctionality: false,
  },
  {
    title: "Order",
    isNumber: true,
    orderFunctionality: true,
    OrderTitle: "Order",
    dbName: "order",
  },
  {
    title: "Active",
    isNumber: true,
    orderFunctionality: true,
    OrderTitle: "Active",
    dbName: "active",
  },
  {
    title: "Actions",
    orderFunctionality: false,
  },
];
