const handleImg = (
  e: React.ChangeEvent<HTMLInputElement>,
  setImgFunction: React.Dispatch<React.SetStateAction<string>>
) => {
  const file = e.target.files?.[0];
  if (file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      setImgFunction(reader.result as string);
    };
    reader.readAsDataURL(file);
  }
};

export default handleImg;
