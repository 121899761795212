// File: AddForm.tsx
import React from "react";
import { useForm } from "react-hook-form";
import { ProjectService } from "../../../../../services/_index";
import {
  ImageUploader,
  TextInput,
  KeyFeaturesInput,
  DescriptionInput,
  WebsiteUrlInput,
  ColorPaletteInput,
  BackgroundColorInput,
  OrderInput,
  TagsInput,
  RadioInput,
} from "../../../../../components/_index";
import { formFields } from "../constants/formFields";
const AddForm: React.FC<{ hiddenAddSection: any }> = ({ hiddenAddSection }) => {
  const {
    register,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    shouldFocusError: true,
  });

  const handleAddProject = async (data: any) => {
    try {
      await ProjectService.createProject(data);
      hiddenAddSection(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form onSubmit={handleSubmit((data) => handleAddProject(data))}>
      <div className="inside_form">
        <ImageUploader
          name="card_image_path"
          label="Card Image"
          register={register}
          setValue={setValue}
          errors={errors}
        />

        <div className="other_images">
          <div className="title">Images*</div>
          <div className="images">
            {["detail_image_1_path", "detail_image_2_path"].map((name) => (
              <ImageUploader
                key={name}
                name={name}
                register={register}
                setValue={setValue}
                errors={errors}
              />
            ))}
          </div>
        </div>

        {formFields.map((field) => (
          <TextInput
            key={field.name}
            {...field}
            register={register}
            errors={errors}
          />
        ))}

        <KeyFeaturesInput
          key={"key_features"}
          control={control}
          errors={errors}
          setValue={setValue}
        />

        <DescriptionInput
          register={register}
          errors={errors}
          name="description"
          label="Description"
        />
        <WebsiteUrlInput
          register={register}
          errors={errors}
          name="website_url"
          label="Website URL"
        />
        <ColorPaletteInput control={control} errors={errors} />
        <BackgroundColorInput control={control} errors={errors} />
        <OrderInput register={register} errors={errors} />
        <TagsInput control={control} errors={errors} />

        <RadioInput
          name="is_featured"
          label="Featured"
          options={[
            { value: "0", label: "No", isDefault: true },
            { value: "1", label: "Yes" },
          ]}
          register={register}
        />

        <RadioInput
          name="active"
          label="Active"
          options={[
            { value: "0", label: "No" },
            { value: "1", label: "Yes", isDefault: true },
          ]}
          register={register}
        />
      </div>

      <div className="actions">
        <div className="discard" onClick={hiddenAddSection}>
          Discard
        </div>
        <button type="submit" className="submit">
          Submit
        </button>
      </div>
    </form>
  );
};

export default AddForm;
