// components/SearchBar.tsx
import React from "react";

export const SearchBar: React.FC<any> = ({ myProps }) => {
  const { setSearchQuery } = myProps;
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="right">
      <div className="form">
        <input type="text" onChange={handleSearch} placeholder="Search..." />
      </div>
    </div>
  );
};
