import { GlobalProvider } from "./context/globalContext";
import RouterConfig from "./routes/_RouterConfig";
import { RouterProvider } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <GlobalProvider>
        <RouterProvider router={RouterConfig} />
      </GlobalProvider>
    </div>
  );
}

export default App;
