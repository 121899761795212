import React from "react";
import { BiError } from "react-icons/bi";

export const OrderInput: React.FC<any> = ({
  name = "order",
  label = "Order",
  register,
  errors,
}) => (
  <div className="input_box">
    <div className="input_title">{label}*</div>
    <input
      type={"number"}
      min={0}
      placeholder="0"
      {...register(name, {
        valueAsNumber: "Order must be a number",
        required: "This is Required",
        min: {
          value: 0,
          message: "Order must be greater than or equal to 0",
        },
      })}
    />
    {errors[name] && (
      <div className="validate">
        <div className="sym">
          <BiError />
        </div>
        {errors[name].message}
      </div>
    )}
  </div>
);
