import React from "react";
import TimeAgo from "react-timeago";

export const RecentActivity: React.FC = () => {
  // const data = myData["7_day"].recentActivitis.items.slice(0, 5);
  return (
    <div className={"recent_activity"}>
      <div className={"header"}>
        <div className={"title"}>Recent activity</div>
      </div>
      <div className={"activities"}>
        <div className={"activity"}>
          <div className={"li"}>
            {/* <div className={`circle ${index % 2 !== 0 && "circle_2"}`}></div> */}
            <div className={`circle circle_2`}></div>
            <div className={"timing"}>
              <TimeAgo
                date={
                  new Date().getTime() - Math.floor(Math.random() * 1000000000)
                }
              />
            </div>
          </div>
          <p className={"text"}>
            delete <span className={"highlight"}>project</span>
          </p>
        </div>
        <div className={"activity"}>
          <div className={"li"}>
            {/* <div className={`circle ${index % 2 !== 0 && "circle_2"}`}></div> */}
            <div className={`circle`}></div>
            <div className={"timing"}>
              <TimeAgo
                date={
                  new Date().getTime() - Math.floor(Math.random() * 1000000000)
                }
              />
            </div>
          </div>
          <p className={"text"}>
            create <span className={"highlight"}>Tag</span>
          </p>
        </div>
      </div>
    </div>
  );
};
