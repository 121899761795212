import { createBrowserRouter } from "react-router-dom";
import {
  RootLayout,
  HomePage,
  LoginPage,
  ProjectList,
  EmployeeList,
  TagList,
  TestimonialList,
} from "../pages/_index";
import { ProtectedLayout } from "../layouts/_index";
import { AuthProvider } from "../context/authContext";
import { Error404 } from "../components/_index";

const RouterConfig = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthProvider>
        <RootLayout />
      </AuthProvider>
    ),
    errorElement: <Error404 />,
    children: [
      {
        element: <ProtectedLayout />,
        children: [
          { index: true, element: <HomePage /> },
          {
            path: "/tables",
            children: [
              { path: "projects", element: <ProjectList /> },
              { path: "tags", element: <TagList /> },
              { path: "employees", element: <EmployeeList /> },
              { path: "testimonials", element: <TestimonialList /> },
            ],
          },
        ],
      },

      { path: "login", element: <LoginPage /> },
    ],
  },
]);

export default RouterConfig;
