import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { BiError } from "react-icons/bi";
import { FetchData } from "../../../services/_index";

interface Tag {
  id: string;
  name: string;
}

export const TagsInput: React.FC<any> = ({ control, errors }) => {
  const { tags, isLoading } = useFetchTags();

  if (isLoading) {
    return <div>Loading tags...</div>;
  }

  return (
    <div className="input_box">
      <div className="input_title">Tags*</div>
      <Controller
        name="tags"
        control={control}
        rules={{ required: "Please select at least one tag" }}
        render={({ field }) => {
          const selectedTagIds =
            field.value?.map((tag: any) => tag.value) || [];
          // @ts-ignore
          const filteredOptions = tags.data
            .filter((tag: Tag) => !selectedTagIds.includes(tag.id.toString()))
            .map(({ name, id }: any) => ({
              value: id.toString(),
              label: name,
            }));

          return (
            <Select
              {...field}
              isMulti
              options={filteredOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select Tags"
            />
          );
        }}
      />
      {errors.tags && (
        <div className="validate">
          <div className="sym">
            <BiError />
          </div>
          {errors.tags.message}
        </div>
      )}
    </div>
  );
};

export const useFetchTags = () => {
  const [tags, setTags] = useState<Tag[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    FetchData({
      setItems: setTags,
      setIsLoading: setIsLoading,
      path: "/api/tags",
      options: {
        withoutLoading: true,
        perPage: 100,
      },
    }).finally(() => setIsLoading(false));
  }, []);

  return { tags, isLoading };
};
