import React from "react";
import { BiError } from "react-icons/bi";

export const TextInput: React.FC<any> = ({
  name,
  label,
  type,
  placeholder,
  register,
  errors,
  isRequired = true,
}) => (
  <div className="input_box">
    <div className="input_title">{label}</div>
    <input
      type={type}
      min={0}
      placeholder={placeholder}
      {...register(name, { required: isRequired && `${label} is required` })}
    />
    {errors[name] && (
      <div className="validate">
        <div className="sym">
          <BiError />
        </div>
        {errors[name].message}
      </div>
    )}
  </div>
);
