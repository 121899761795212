import { toast } from "react-hot-toast";
import api from "./api";

interface DashboardData {
  data_boxes: {
    projects: number;
    employees: number;
    testimonials: number;
  };
  projects: Array<{
    slug: string;
    title: string;
    subtitle: string;
    card_image_path: string;
  }>;
}

type SetStateFunction<T> = (value: T | ((prevState: T) => T)) => void;

class DashboardService {
  private static async handleRequest(
    url: string,
    method: "GET" | "POST" | "PATCH" | "DELETE",
    data?: any,
    params?: Record<string, any>,
    options: { withoutLoading?: boolean } = {}
  ): Promise<any> {
    const { withoutLoading = false } = options;
    let toastId: string | undefined;

    if (!withoutLoading) {
      toastId = toast.loading("Fetching dashboard data...");
    }

    try {
      const config: any = { method, url };
      if (data) config.data = data;
      if (params) config.params = params;

      const response = await api(config);

      if (!withoutLoading && toastId) {
        toast.success(response.data.message || "Data fetched successfully", {
          id: toastId,
        });
      }

      return response.data;
    } catch (error: any) {
      console.error("Error:", error);
      const message =
        error.response?.data?.message || "An error occurred. Please try again.";

      if (!withoutLoading && toastId) {
        toast.error(message, { id: toastId });
      }

      throw error;
    }
  }

  public static async fetchDashboardData(
    setDashboardData: SetStateFunction<DashboardData>,
    setIsLoading: SetStateFunction<boolean>
  ): Promise<void> {
    setIsLoading(true);
    toast.remove();

    try {
      const response = await this.handleRequest("/api/dashboard-data", "GET");

      if (response.status) {
        setDashboardData(response.dashboard_data);
      }
    } finally {
      setIsLoading(false);
    }
  }
}

export default DashboardService;