import { toast } from "react-hot-toast";
import api from "./api";

interface TestimonialData {
  [key: string]: any;
}

interface PaginationParams {
  page?: number;
  per_page?: number;
  order_by?: string;
  order_direction?: "asc" | "desc";
}

interface SearchParams extends PaginationParams {
  search?: string;
  active?: boolean;
  is_featured?: boolean;
}

interface FetchDataOptions extends SearchParams {
  withoutLoading?: boolean;
}

type SetStateFunction<T> = (value: T | ((prevState: T) => T)) => void;

class TestimonialService {
  private static readonly FILE_FIELDS = ["image_path"];

  private static readonly DATA_FIELDS = [
    "name",
    "company",
    "content",
    "rating",
    "order",
    "is_featured",
    "active",
  ];

  private static prepareFormData(
    data: TestimonialData,
    isUpdate: boolean = false,
    originalData: TestimonialData = {}
  ): FormData {
    const formData = new FormData();

    this.FILE_FIELDS.forEach((field) => {
      if (data[`${field}`]?.[0] instanceof File) {
        formData.append(field, data[`${field}`][0]);
      }
    });

    if (!isUpdate) {
      this.DATA_FIELDS.forEach((key) => {
        if (data[key] !== undefined) {
          formData.append(key, this.formatValue(key, data[key]));
        }
      });
    } else {
      this.DATA_FIELDS.forEach((key) => {
        let formValue = data[key];
        let backendValue = originalData[key];

        if (key === "active" || key === "is_featured") {
          formValue = formValue === "1" || formValue === 1 ? true : false;
          backendValue = backendValue === true ? true : false;
        }
        if (formValue !== backendValue) {
          formData.append(key, data[key]);
        }
      });
    }

    return formData;
  }

  private static formatValue(key: string, value: any): any {
    if (key === "active" || key === "is_featured") {
      return value === "1" || value === 1 || value === true ? "1" : "0";
    }
    return value;
  }

  private static async handleRequest(
    url: string,
    method: "GET" | "POST" | "PATCH" | "DELETE",
    data?: FormData | null,
    params?: Record<string, any>,
    options: { withoutLoading?: boolean } = {}
  ): Promise<any> {
    const { withoutLoading = false } = options;
    let toastId: string | undefined;

    if (!withoutLoading) {
      toastId = toast.loading("Processing request...", {
        style: {
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
        },
      });
    }

    try {
      const config: any = { method, url };
      if (data) config.data = data;
      if (params) config.params = params;

      const response = await api(config);

      if (!withoutLoading && toastId) {
        toast.success(response.data.message || "Operation successful", {
          id: toastId,
          duration: 5000,
          style: {
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          },
        });
      }

      return response.data;
    } catch (error: any) {
      console.error("Error:", error);
      const message =
        error.response?.data?.message || "An error occurred. Please try again.";

      if (!withoutLoading && toastId) {
        toast.error(message, {
          id: toastId,
          duration: 5000,
          style: {
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          },
        });
      }

      throw error;
    }
  }

  public static async fetchTestimonials(
    setItems: SetStateFunction<any>,
    setIsLoading: SetStateFunction<boolean>,
    options: FetchDataOptions = {}
  ): Promise<void> {
    const {
      withoutLoading = false,
      search,
      active,
      is_featured,
      order_by = "order",
      order_direction = "asc",
      per_page = 15,
      page = 1,
    } = options;

    if (!withoutLoading) {
      setIsLoading(true);
      toast.remove();
    }

    try {
      const params: Record<string, string> = {
        order_by,
        order_direction,
        per_page: per_page.toString(),
        page: page.toString(),
      };

      if (search) params.search = search;
      if (active !== undefined) params.active = active.toString();
      if (is_featured !== undefined)
        params.is_featured = is_featured.toString();

      const response = await this.handleRequest(
        "/api/testimonials",
        "GET",
        null,
        params,
        { withoutLoading }
      );

      if (response.status) {
        setItems(response.items);
      }
    } finally {
      setIsLoading(false);
    }
  }

  public static async getTestimonial(slug: string): Promise<any> {
    return this.handleRequest(`/api/testimonials/${slug}`, "GET");
  }

  public static async createTestimonial(data: TestimonialData): Promise<any> {
    const formData = this.prepareFormData(data);
    return this.handleRequest("/api/testimonials", "POST", formData);
  }

  public static async updateTestimonial(
    id: string,
    data: TestimonialData,
    originalData: TestimonialData
  ): Promise<any> {
    const formData = this.prepareFormData(data, true, originalData);
    if ([...formData.entries()].length === 0) {
      toast.error(
        "No changes detected. Please modify the form before submitting.",
        {
          duration: 5000,
        }
      );
      return;
    }
    return this.handleRequest(
      `/api/testimonials/${id}?_method=PATCH`,
      "POST",
      formData
    );
  }

  public static async deleteTestimonials(ids: string | string[]): Promise<any> {
    const formData = new FormData();
    if (Array.isArray(ids)) {
      ids.forEach((id, index) => {
        formData.append(`ids[${index}]`, id);
      });
    } else {
      formData.append("ids[]", ids);
    }

    return this.handleRequest(
      "/api/testimonials?_method=DELETE",
      "POST",
      formData
    );
  }

  public static async toggleTestimonialActive(id: string): Promise<any> {
    return this.handleRequest(`/api/testimonials/${id}/toggle-active`, "PATCH");
  }

  public static async reorderTestimonials(
    TestimonialIds: string[]
  ): Promise<any> {
    const formData = new FormData();
    TestimonialIds.forEach((id, index) => {
      formData.append(`Testimonials[${index}]`, id);
    });
    return this.handleRequest("/api/testimonials/reorder", "PATCH", formData);
  }

  public static async gettestimonialAuditLogs(id: string): Promise<any> {
    return this.handleRequest(`/api/testimonials/${id}/audit-logs`, "GET");
  }
}

export default TestimonialService;
