"use client";

import { MdOutlineCancel } from "react-icons/md";
import AddForm from "./form";

const Add: React.FC<any> = ({ hiddenAddSection }) => {
  return (
    <section
      className={`form_section add`}
      onClick={(e: any) => {
        [...e.target.classList].includes("add") && hiddenAddSection();
      }}
    >
      <div className={"background"}>
        <div className={"header"}>
          <div className={"form_section_title"}> Add Project</div>
          <div className={"exit"} onClick={() => hiddenAddSection()}>
            <MdOutlineCancel />
          </div>
        </div>
        <AddForm hiddenAddSection={hiddenAddSection} />
      </div>
    </section>
  );
};

export default Add;
