const resetFormData = (data: any, reset: any, setResetImages: any) => {
  reset({
    ...data,
    is_featured: data.is_featured ? "1" : "0",
    active: data.active ? "1" : "0",
  });
  setResetImages(true);
};

export default resetFormData;
