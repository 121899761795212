import { toast } from "react-hot-toast";
import api from "./api";

interface FetchDataParams {
  setItems: (items: any[]) => void;
  setIsLoading: (isLoading: boolean) => void;
  path: string;
  options?: {
    withoutLoading?: boolean;
    search?: string;
    active?: boolean;
    isFeatured?: boolean;
    orderBy?: string;
    orderDirection?: "asc" | "desc";
    perPage?: number;
    page?: number;
  };
}

export const FetchData = async ({
  setItems,
  setIsLoading,
  path,
  options = {},
}: FetchDataParams): Promise<void> => {
  const {
    withoutLoading = false,
    search,
    active,
    isFeatured,
    orderBy = "created_at",
    orderDirection = "desc",
    perPage = 15,
    page = 1,
  } = options;

  if (!withoutLoading) {
    setIsLoading(true);
    toast.remove();
  }

  const toastStyle = {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  } as const;

  const toastId =
    !withoutLoading &&
    toast.loading("Fetching data... This may take a few seconds.", {
      style: toastStyle,
    });

  try {
    const params = new URLSearchParams({
      ...(search && { search }),
      ...(active !== undefined && { active: active.toString() }),
      ...(isFeatured !== undefined && { is_featured: isFeatured.toString() }),
      ...(orderBy && { order_by: orderBy }),
      order_direction: orderDirection,
      per_page: perPage.toString(),
      page: page.toString(),
    });

    const response = await api.get(`${path}?${params.toString()}`);

    if (response.data.status) {
      setItems(response.data.items);
      if (!withoutLoading) {
        toast.success(response.data.message, {
          id: toastId as string,
          style: toastStyle,
          duration: 5000,
        });
      }
    } else {
      if (!withoutLoading) {
        toast.error(response.data.message, {
          id: toastId as string,
          style: toastStyle,
          duration: 5000,
        });
      }
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    if (!withoutLoading) {
      toast.error("An error occurred while fetching data. Please try again.", {
        id: toastId as string,
        style: toastStyle,
        duration: 5000,
      });
    }
  } finally {
    setIsLoading(false);
  }
};
