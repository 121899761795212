import { useEffect } from "react";
import "../style/errors.css";
import { useNavigate } from "react-router-dom";

export const Error404: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 3000);
  }, [navigate]);
  useEffect(() => {
    document.title = "404 - Not Found";
  }, []);
  return (
    <div className="error404Page">
      <div className="error404-error">404</div>
      <br />
      <br />
      <span className="error404-info">File not found</span>
    </div>
  );
};
