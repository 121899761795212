import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { IconType } from "react-icons";


interface NavLinkProps {
  content: NavLinkContent;
  minimizeShowBar: boolean;
  myMaxWidth: boolean;
}

interface NavLinkContent {
  title: string;
  link: string;
  icon: {
    Before: IconType;
    After: IconType;
  };
  options?: string[];
}

interface OptionsLinkProps extends NavLinkProps {
  setMinimizeShowBar: React.Dispatch<React.SetStateAction<boolean>>;
}

export const OptionsLink: React.FC<OptionsLinkProps> = ({
  minimizeShowBar,
  content,
  setMinimizeShowBar,
  myMaxWidth,
}) => {
  const { title, icon, options, link }:any = content;
  const { Before, After } = icon;
  const [showOptions, setShowOptions] = useState(false);

  return (
    <div
      className="link"
      style={{ color: showOptions ? "rgb(18, 145, 69)" : "#8f9bba" }}
    >
      <div
        className="head"
        onClick={() => {
          setShowOptions((prev) => !prev);
          setMinimizeShowBar(false);
        }}
      >
        <div
          className="icon"
          style={{
            justifyContent: myMaxWidth
              ? minimizeShowBar
                ? "center"
                : "flex-start"
              : "flex-start",
          }}
        >
          {showOptions ? <After /> : <Before />}
        </div>
        {myMaxWidth ? (
          !minimizeShowBar && (
            <div className="title">
              {title} <IoIosArrowForward className="arrow" />
            </div>
          )
        ) : (
          <div className="title">
            {title} <IoIosArrowForward className="arrow" />
          </div>
        )}
      </div>

      <div className="options" style={{ height: showOptions ? "auto" : 0 }}>
        {options.map((option:any, index:any) => (
          <Link to={`/${link}/${option}`} className="option" key={index}>
            {option}
          </Link>
        ))}
      </div>
    </div>
  );
};

export const NormalLink: React.FC<NavLinkProps> = ({
  minimizeShowBar,
  content,
  myMaxWidth,
}) => {
  const { title, icon, link } = content;
  const { Before } = icon;

  return (
    <Link to={link} className="link">
      <div className="head">
        <div
          className="icon"
          style={{
            justifyContent: myMaxWidth
              ? minimizeShowBar
                ? "center"
                : "flex-start"
              : "flex-start",
          }}
        >
          <Before />
        </div>
        {myMaxWidth ? (
          !minimizeShowBar && <div className="title">{title}</div>
        ) : (
          <div className="title">{title}</div>
        )}
      </div>
    </Link>
  );
};
