import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../context/authContext";
import { Navbar, StartLoader } from "../components/_index";
import { useGlobalContext } from "../context/globalContext";

export const ProtectedLayout: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth();
  const { myPath } = useGlobalContext();
  if (isLoading) {
    return <StartLoader />;
  }

  return isAuthenticated ? (
    <>
      <Navbar />
      <main className="container">
        <div className="container_page_title">
          <div className="container_mini_title">Pages / {myPath}</div>
          <div className="container_big_title">{myPath}</div>
        </div>
        <Outlet />
      </main>
    </>
  ) : (
    <Navigate to="/login" replace />
  );
};
