import { useEffect, useRef, useState } from "react";
import { LeftSideNavbar, TopSideNavbar } from "./components/_index";
import "../../style/navbar.css";
import { useWindowSize } from "../../hooks/useWindowSize";

const Navbar: React.FC = () => {
  const [minimizeShowBar, setMinimizeShowBar] = useState<boolean>(false);
  const { width } = useWindowSize();
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      setTimeout(() => {
        setMinimizeShowBar(true);
      }, 3000);
    }
  }, []);

  useEffect(() => {
    const myMaxWidth = width > 1200;
    const myContainer: any = document.querySelector(".container");
    if (myContainer)
      myContainer.style.margin =
        myMaxWidth &&
        (minimizeShowBar ? "110px 20px 20px 130px" : "110px 20px 20px 320px");
  }, [minimizeShowBar, width]);

  return (
    <nav className={"navbar"}>
      <LeftSideNavbar
        minimizeShowBar={minimizeShowBar}
        setMinimizeShowBar={setMinimizeShowBar}
      />
      <TopSideNavbar
        minimizeShowBar={minimizeShowBar}
        setMinimizeShowBar={setMinimizeShowBar}
      />
    </nav>
  );
};
export default Navbar;
