import React from "react";

export const RadioInput: React.FC<any> = ({
  name,
  label,
  options,
  register,
}) => (
  <div className="input_box">
    <div className="input_radio">
      <div className="input_title">{label}:</div>
      {options.map(({ value, label, isDefault = false }: any, index: any) => (
        <React.Fragment key={value}>
          <input
            type="radio"
            value={value}
            defaultChecked={isDefault}
            {...register(name, { required: true })}
          />
          {label}
        </React.Fragment>
      ))}
    </div>
  </div>
);
