import React from "react";
import { Controller } from "react-hook-form";
import { BiError } from "react-icons/bi";
import { MultipleColorPicker } from "./multipleColorPicker";

export const ColorPaletteInput: React.FC<any> = ({
  control,
  errors,
}) => (
  <div className="form-group input_box">
    <label className="input_title">Color Palette*</label>
    <Controller
      name="colorPalette"
    //   defaultValue={myDefaultValue}
      control={control}
      rules={{
        required: "Please select at least one color",
        validate: (value) => {
          if (value.length < 2) return "Minimum 2 colors required";
          if (value.length > 5) return "Maximum 5 colors allowed";
          return true;
        },
      }}
      render={({ field }) => <MultipleColorPicker {...field} />}
    />
    {errors.colorPalette && (
      <div className="validate">
        <div className="sym">
          <BiError />
        </div>
        {errors.colorPalette.message}
      </div>
    )}
  </div>
);
