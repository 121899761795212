import { TbEye, TbEyeClosed } from "react-icons/tb";

const LoginForm: React.FC<any> = ({
  handleSubmit,
  onSubmit,
  register,
  errors,
  isPassword,
  setIsPassword,
}) => {
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="left">
      <div className="form_title">Sign In</div>
      <div className="form_message">
        Enter your email and password to sign in!
      </div>
      <div className="input_box">
        <div className="input_title">Email*</div>
        <div className="myInput">
          <input
            type="email"
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address",
              },
            })}
            placeholder="info@shafaq.sa"
          />
          {errors.email && (
            <span className="error">{errors.email.message}</span>
          )}
        </div>
      </div>
      <div className="input_box">
        <div className="input_title">Password*</div>
        <div className="myInput">
          <input
            type={isPassword ? "password" : "text"}
            {...register("password", {
              required: "Password is required",
              minLength: {
                value: 8,
                message: "Password must be at least 8 characters",
              },
            })}
            placeholder="Min 8 characters..."
          />
          {errors.password && (
            <span className="error">{errors.password.message}</span>
          )}
          <div
            className="eye_icon"
            onClick={() => setIsPassword((prev: any) => !prev)}
          >
            {isPassword ? <TbEyeClosed /> : <TbEye />}
          </div>
        </div>
      </div>
      <button className="login_submit" type="submit">
        Sign In
      </button>
    </form>
  );
};

export default LoginForm;
