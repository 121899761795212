// File: components/ImageUploader.tsx
import React, { useEffect, useState } from "react";
import { BiError } from "react-icons/bi";
import { handleImg } from "../../../services/_index";
import errorImage from "../../../assets/images/svg/posting_image_2.svg";

export const ImageUploader: React.FC<any> = ({
  name,
  register,
  setValue,
  errors,
  myImg = errorImage,
  reset = null,
  isRequired = true,
}) => {
  const [newImg, setNewImg] = useState(myImg);

  useEffect(() => {
    if (reset) {
      setNewImg(myImg);
      setValue(name, null);
    }
  }, [reset, myImg, name, setValue]);

  return (
    <div className="img_box">
      <div className="img">
        <img src={newImg} alt="img" style={{ objectFit: "contain" }} />
        <input
          type="file"
          id={name}
          className="uploadInput"
          onChange={(e: any) => {
            setValue(name, e.target.files);
          }}
          {...register(name, {
            onChange: (e: any) => handleImg(e, setNewImg),
            required: isRequired ? "This is Required" : false,
            validate: (value: FileList) =>
              !value ||
              value.length === 0 ||
              value[0].size / 1024 < 20480  ||
              "Image must be less than 20MB",
          })}
        />
      </div>
      <div className="role">JPG or PNG or WEBP no larger than 20 MB</div>

      {errors[name] && (
        <div className="validate">
          <div className="sym">
            <BiError />
          </div>
          {errors[name].message}
        </div>
      )}
    </div>
  );
};
