export const Titles = [
  {
    title: "ID",
    isNumber: true,
    orderFunctionality: true,
    OrderTitle: "Time",
    dbName: "created_at",
  },
  {
    title: "Image",
    orderFunctionality: false,
  },
  {
    title: "Slug",
    isNumber: false,
    orderFunctionality: true,
    OrderTitle: "Slug",
    dbName: "slug",
  },
  {
    title: "Title",
    isNumber: false,
    orderFunctionality: true,
    OrderTitle: "Title",
    dbName: "title",
  },
  {
    title: "Subtitle",
    isNumber: false,
    orderFunctionality: true,
    OrderTitle: "Subtitle",
    dbName: "subtitle",
  },
  {
    title: "Description",
    orderFunctionality: false,
  },
  {
    title: "Website Url",
    isNumber: false,
    orderFunctionality: true,
    OrderTitle: "Website Url",
    dbName: "website_url",
  },
  {
    title: "Key Features",
    orderFunctionality: false,
  },
  {
    title: "Color Palette",
    orderFunctionality: false,
  },
  {
    title: "B.G Color",
    orderFunctionality: false,
  },
  {
    title: "Framework",
    isNumber: false,
    orderFunctionality: true,
    OrderTitle: "Framework",
    dbName: "framework",
  },
  {
    title: "Programming Language",
    isNumber: false,
    orderFunctionality: true,
    OrderTitle: "Programming Language",
    dbName: "programming_language",
  },
  {
    title: "Backend System",
    isNumber: false,
    orderFunctionality: true,
    OrderTitle: "Backend System",
    dbName: "backend_system",
  },
  {
    title: "Tags",
    orderFunctionality: false,
  },
  {
    title: "Order",
    isNumber: true,
    orderFunctionality: true,
    OrderTitle: "Order",
    dbName: "order",
  },
  {
    title: "Image 1",
    orderFunctionality: false,
  },
  {
    title: "Image 2",
    orderFunctionality: false,
  },
  {
    title: "Featured",
    isNumber: true,
    orderFunctionality: true,
    OrderTitle: "Featured",
    dbName: "is_featured",
  },
  {
    title: "Active",
    isNumber: true,
    orderFunctionality: true,
    OrderTitle: "Active",
    dbName: "active",
  },
  {
    title: "Actions",
    orderFunctionality: false,
  },
];
