import { toast } from "react-hot-toast";
import api from "./api";

interface TagData {
  [key: string]: any;
}

interface PaginationParams {
  page?: number;
  per_page?: number;
  order_by?: string;
  order_direction?: "asc" | "desc";
}

interface SearchParams extends PaginationParams {
  search?: string;
}

interface FetchDataOptions extends SearchParams {
  withoutLoading?: boolean;
}

type SetStateFunction<T> = (value: T | ((prevState: T) => T)) => void;

class TagService {
  private static readonly DATA_FIELDS = ["slug", "name"];

  private static prepareFormData(
    data: TagData,
    isUpdate: boolean = false,
    originalData: TagData = {}
  ): FormData {
    const formData = new FormData();

    if (!isUpdate) {
      this.DATA_FIELDS.forEach((key) => {
        if (data[key] !== undefined) {
          formData.append(key, data[key]);
        }
      });
    } else {
      this.DATA_FIELDS.forEach((key) => {
        let formValue = data[key];
        let backendValue = originalData[key];

        if (formValue !== backendValue) {
          formData.append(key, data[key]);
        }
      });
    }

    return formData;
  }

  private static async handleRequest(
    url: string,
    method: "GET" | "POST" | "PATCH" | "DELETE",
    data?: FormData | null,
    params?: Record<string, any>,
    options: { withoutLoading?: boolean } = {}
  ): Promise<any> {
    const { withoutLoading = false } = options;
    let toastId: string | undefined;

    if (!withoutLoading) {
      toastId = toast.loading("Processing request...", {
        style: {
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
        },
      });
    }

    try {
      const config: any = { method, url };
      if (data) config.data = data;
      if (params) config.params = params;

      const response = await api(config);

      if (!withoutLoading && toastId) {
        toast.success(response.data.message || "Operation successful", {
          id: toastId,
          duration: 5000,
          style: {
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          },
        });
      }

      return response.data;
    } catch (error: any) {
      console.error("Error:", error);
      const message =
        error.response?.data?.message || "An error occurred. Please try again.";

      if (!withoutLoading && toastId) {
        toast.error(message, {
          id: toastId,
          duration: 5000,
          style: {
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          },
        });
      }

      throw error;
    }
  }

  public static async fetchTags(
    setItems: SetStateFunction<any>,
    setIsLoading: SetStateFunction<boolean>,
    options: FetchDataOptions = {}
  ): Promise<void> {
    const {
      withoutLoading = false,
      search,
      order_by = "order",
      order_direction = "asc",
      per_page = 15,
      page = 1,
    } = options;

    if (!withoutLoading) {
      setIsLoading(true);
      toast.remove();
    }

    try {
      const params: Record<string, string> = {
        order_by,
        order_direction,
        per_page: per_page.toString(),
        page: page.toString(),
      };

      if (search) params.search = search;
      const response = await this.handleRequest(
        "/api/tags",
        "GET",
        null,
        params,
        { withoutLoading }
      );

      if (response.status) {
        setItems(response.items);
      }
    } finally {
      setIsLoading(false);
    }
  }

  public static async getTag(slug: string): Promise<any> {
    return this.handleRequest(`/api/tags/${slug}`, "GET");
  }

  public static async createTag(data: TagData): Promise<any> {
    const formData = this.prepareFormData(data);
    return this.handleRequest("/api/tags", "POST", formData);
  }

  public static async updateTag(
    id: string,
    data: TagData,
    originalData: TagData
  ): Promise<any> {
    const formData = this.prepareFormData(data, true, originalData);
    if ([...formData.entries()].length === 0) {
      toast.error(
        "No changes detected. Please modify the form before submitting.",
        {
          duration: 5000,
        }
      );
      return;
    }
    return this.handleRequest(
      `/api/tags/${id}?_method=PATCH`,
      "POST",
      formData
    );
  }

  public static async deleteTags(ids: string | string[]): Promise<any> {
    const formData = new FormData();
    if (Array.isArray(ids)) {
      ids.forEach((id, index) => {
        formData.append(`ids[${index}]`, id);
      });
    } else {
      formData.append("ids[]", ids);
    }

    return this.handleRequest("/api/tags?_method=DELETE", "POST", formData);
  }

  //   public static async getTagAuditLogs(id: string): Promise<any> {
  //     return this.handleRequest(`/api/Tags/${id}/audit-logs`, "GET");
  //   }
}

export default TagService;
