import toast from "react-hot-toast";
import {
  EmployeeService,
  ProjectService,
  TagService,
  TestimonialService,
} from "../../services/_index";

export const ConfirmDeleteItem = (
  ids: string | string[],
  setFetchData: React.Dispatch<React.SetStateAction<boolean>>,
  setCheckboxArr: React.Dispatch<React.SetStateAction<string[]>>,
  query: string
) => {
  const style: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  };

  toast.remove();
  toast(
    (t) => (
      <div style={style}>
        <div>Delete Operation</div>
        <div>
          <button
            className="Dismiss"
            onClick={() => {
              toast.dismiss(t.id);
              setCheckboxArr([]);
            }}
          >
            Dismiss
          </button>
          <button
            className="confirm"
            onClick={() => {
              confirm(ids, setFetchData, setCheckboxArr, query);
              toast.dismiss(t.id);
            }}
          >
            Confirm
          </button>
        </div>
      </div>
    ),
    { duration: 10000 }
  );
};

export const confirm = async (
  ids: string | string[],
  setFetchData: React.Dispatch<React.SetStateAction<boolean>>,
  setCheckboxArr: React.Dispatch<React.SetStateAction<string[]>>,
  query: string
) => {
  try {
    if (query === "tag") await TagService.deleteTags(ids);
    if (query === "project") await ProjectService.deleteProjects(ids);
    if (query === "employee") await EmployeeService.deleteEmployees(ids);
    if (query === "testimonial")
      await TestimonialService.deleteTestimonials(ids);
    setFetchData((prev) => !prev);
    setCheckboxArr([]);
  } catch (error) {
    console.error(error);
  }
};
