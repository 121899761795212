const resetFormData = (
  data: any,
  reset: any,
  setResetImages: any,
  setValue: any,
  setMyKeyFeatures: any,
  setMyColorPalette: any
) => {
  reset({
    ...data,
    is_featured: data.is_featured ? "1" : "0",
    active: data.active ? "1" : "0",
  });
  setResetImages(true);

  const keyFeaturesArray = data.key_features
    .split(", ")
    .map((feature: any) => ({
      value: feature,
      label: feature,
    }));
  setValue("key_features", data.key_features);
  setMyKeyFeatures(keyFeaturesArray);

  const tagsArray = data.tags.map((tag: any) => ({
    value: tag.id.toString(),
    label: tag.name,
  }));
  setValue("tags", tagsArray);

  try {
    const colorPalette = JSON.parse(data.color_palette);
    setMyColorPalette(colorPalette);
    const myColorsArr = Object.entries(colorPalette)
      .filter(
        ([key, value]) => typeof value === "string" && value.startsWith("#")
      )
      .map(([_, color]: any) => color.toUpperCase());
    setValue("colorPalette", myColorsArr);
    setValue("ring", colorPalette.ring);
    // setMyColorPalette(colorPalette);
  } catch (error) {
    console.error("Error parsing color_palette:", error);
  }
};

export default resetFormData;
