"use client";

import { MdOutlineCancel } from "react-icons/md";
import UpdateForm from "./form";

const Update: React.FC<any> = ({ hiddenUpdateSection,details }) => {
  return (
    <section
      className={`form_section add`}
      onClick={(e: any) => {
        [...e.target.classList].includes("add") && hiddenUpdateSection();
      }}
    >
      <div className={"background"}>
        <div className={"header"}>
          <div className={"form_section_title"}> Update Testimonial</div>
          <div className={"exit"} onClick={() => hiddenUpdateSection()}>
            <MdOutlineCancel />
          </div>
        </div>
        <UpdateForm hiddenUpdateSection={hiddenUpdateSection} details={details}/>
      </div>
    </section>
  );
};

export default Update;
