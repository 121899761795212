import {
  AiOutlineHome,
  AiFillHome,
  AiOutlineEye,
  AiFillEye,
} from "react-icons/ai";
import {
  RiBarChart2Line,
  RiBarChart2Fill,
  RiPagesLine,
  RiPagesFill,
} from "react-icons/ri";
import { BiError, BiSolidError } from "react-icons/bi";
import { IconType } from "react-icons";
interface NavLinkContent {
  title: string;
  link: string;
  icon: {
    Before: IconType;
    After: IconType;
  };
  options?: string[];
}

export const myOptionsLink: NavLinkContent[] = [
  // {
  //   title: "Pages Content",
  //   link: "pages",
  //   icon: {
  //     Before: RiPagesLine,
  //     After: RiPagesFill,
  //   },
  //   options: ["home", "projects", "contact", "footer"],
  // },
  {
    title: "Data Tables",
    link: "tables",
    icon: {
      Before: RiBarChart2Line,
      After: RiBarChart2Fill,
    },
    options: ["projects", "tags", "employees", "testimonials"],
  },
  {
    title: "Errors Messages",
    link: "errors",
    icon: {
      Before: BiError,
      After: BiSolidError,
    },
    options: ["404 Page", "401 Page", "500 Page"],
  },
];

export const myNormalLink: NavLinkContent[] = [
  {
    title: "Main Dashboard",
    link: "/",
    icon: {
      Before: AiOutlineHome,
      After: AiFillHome,
    },
  },
  {
    title: "Activities",
    link: "/activities",
    icon: {
      Before: AiOutlineEye,
      After: AiFillEye,
    },
  },
];
