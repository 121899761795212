import { useCallback, useEffect, useRef, useState } from "react";
import "../../style/home.css";
import {
  DataBoxes,
  DaTaTable,
  Loader,
  RecentActivity,
} from "./components/_index";
import { DashboardService } from "../../services/_index";
import { useGlobalContext } from "../../context/globalContext";

const HomePage: React.FC = () => {
  const [items, setItems] = useState<any>([]);
  const [isLoad, setIsLoad] = useState(true);
  const isInitialMount = useRef(true);
  const { setMyPath } = useGlobalContext();

  const fetchDataCallback = useCallback(() => {
    DashboardService.fetchDashboardData(setItems, setIsLoad);
  }, []);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      fetchDataCallback();
    }
  }, []);


  useEffect(() => {
    setMyPath("Home");
    document.title = "Dashboard";
  }, [setMyPath]);

  return (
    <main className="home-page">
      {isLoad ? (
        <Loader />
      ) : (
        <>
          <DataBoxes data={items.data_boxes} />
          <div className={"data_section_1"}>
            <DaTaTable data={items.projects} />
            <RecentActivity />
          </div>
        </>
      )}
    </main>
  );
};

export default HomePage;
