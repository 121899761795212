import React from 'react';
import { BiError } from 'react-icons/bi';

export const WebsiteUrlInput: React.FC<any> = ({ register, errors,name,label }) => (
  <div className="input_box">
    <div className="input_title">{label}</div>
    <input
      type="url"
      placeholder="https://example.com"
      {...register(name, {
        pattern: {
          value: /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+([a-zA-Z]{2,6})(\/[^\s]*)?$/,
          message: "Invalid URL",
        },
      })}
    />
    {errors[name] && (
      <div className="validate">
        <div className="sym">
          <BiError />
        </div>
        {errors[name].message}
      </div>
    )}
  </div>
);