import React, {  useState } from "react";
import { Controller } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import { BiError } from "react-icons/bi";

export const KeyFeaturesInput: React.FC<any> = ({
  control,
  errors,
  setValue,
  myDefaultValue = [],
}) => {
  const [keyFeatures, setKeyFeatures] =
    useState<{ value: string; label: string }[]>(myDefaultValue);

  const handleKeyFeaturesChange = (newValue: any) => {
    setKeyFeatures(newValue);
    setValue(
      "key_features",
      newValue.map((item: { value: string }) => item.value).join(", ")
    );
  };


  return (
    <div className="input_box">
      <div className="input_title">Key Features*</div>
      <Controller
        name="key_features"
        control={control}
        rules={{ required: "At least one key feature is required" }}
        render={({ field }) => (
          <CreatableSelect
            {...field}
            isMulti
            options={keyFeatures}
            onChange={handleKeyFeaturesChange}
            value={keyFeatures}
            placeholder="Add key features..."
            className="basic-multi-select"
            classNamePrefix="select"
          />
        )}
      />
      {errors["key_features"] && (
        <div className="validate">
          <div className="sym">
            <BiError />
          </div>
          {errors["key_features"].message}
        </div>
      )}
    </div>
  );
};
