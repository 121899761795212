// File: AddForm.tsx
import React from "react";
import { useForm } from "react-hook-form";
import {  TagService } from "../../../../../services/_index";
import { TextInput } from "../../../../../components/_index";
import { formFields } from "../constants/formFields";
const AddForm: React.FC<{ hiddenAddSection: any }> = ({ hiddenAddSection }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    shouldFocusError: true,
  });

  const handleAddTag = async (data: any) => {
    try {

      await TagService.createTag(data);
      hiddenAddSection(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form onSubmit={handleSubmit((data) => handleAddTag(data))}>
      <div className="inside_form">
        {formFields.map((field) => (
          <TextInput
            key={field.name}
            {...field}
            register={register}
            errors={errors}
          />
        ))}
      </div>

      <div className="actions">
        <div className="discard" onClick={hiddenAddSection}>
          Discard
        </div>
        <button type="submit" className="submit">
          Submit
        </button>
      </div>
    </form>
  );
};

export default AddForm;
