export const formFields = [
  {
    name: "name",
    label: "Name",
    type: "text",
    placeholder: "Employee Name",
  },
  {
    name: "position",
    label: "Position",
    type: "text",
    placeholder: "Employee Position",
  },
];
