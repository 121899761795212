import React from "react";

import { FcCancel, FcOk } from "react-icons/fc";
import myBackground from "../../../assets/images/background/bg2-new.jpg";

const Row: React.FC<any> = ({ values: [details, index] }: any) => {
  const baseUrl = process.env.REACT_APP_API_URL + "storage/";
  const {
    id,
    color_palette,
    is_featured,
    order,
    tags,
    card_image_path,
    slug,
    active,
  } = details;

  const myColors = JSON.parse(color_palette);
  const { ring } = myColors;
  

  return (
    <tr className={`${index % 2 > 0 && "cd"}`}>
      <td>{id}</td>
      <td className={"img_td"}>
        <div className={"img"}>
          <img src={baseUrl + card_image_path} alt="img" />
        </div>
      </td>
      <td>{slug}</td>

      <td>
        <div className="color-palette">
          {Object.entries(myColors)
            .filter(([key]) => key !== "ring")
            .map(([_, color]: any, index, array) => (
              <span
                key={index}
                className="my-color"
                style={{ backgroundColor: color }}
              >
                {index < array.length - 1 ? ", " : ""}
              </span>
            ))}
        </div>
      </td>
      <td className={"img_td"}>
        <div className={"img"}>
          <img
            src={myBackground}
            alt="background"
            style={{ filter: `hue-rotate(${ring}deg)`, objectFit: "cover" }}
          />
        </div>
      </td>
      <td>
        <div className={"cat"}>          
          {tags.length}
        </div>
      </td>
      <td>
        <div className="bur">
        {order}
        </div>
      </td>

      <td className={`sym sym_align`}>
        {Number(is_featured) ? <FcOk /> : <FcCancel />}
      </td>
      <td className={`sym sym_align`}>
        {Number(active) ? <FcOk /> : <FcCancel />}
      </td>
    </tr>
  );
};

export default Row;
