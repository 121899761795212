import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { BiLogOut } from "react-icons/bi";
import { useAuth } from "../../../context/authContext";
import { NormalLink, OptionsLink, myNormalLink, myOptionsLink,ShafeqLogo } from "./_index";
import { useWindowSize } from "../../../hooks/_index";

interface LeftSideNavbarProps {
  minimizeShowBar: boolean;
  setMinimizeShowBar: React.Dispatch<React.SetStateAction<boolean>>;
}

const LeftSideNavbar: React.FC<LeftSideNavbarProps> = ({
  minimizeShowBar,
  setMinimizeShowBar,
}) => {
  const location = useLocation();
  const { logout } = useAuth();
  const { width } = useWindowSize();

  const myMaxWidth = width > 1024;

  useEffect(() => {
    if (!myMaxWidth && !minimizeShowBar) {
      document.querySelector("html")!.style.overflow = "hidden";
    } else {
      document.querySelector("html")!.style.overflow = "auto";
    }
  }, [minimizeShowBar, myMaxWidth]);

  useEffect(() => {
    if (!myMaxWidth) {
      setMinimizeShowBar(true);
    }
  }, [location.pathname, setMinimizeShowBar, myMaxWidth]);

  return (
    <div
      className={`left ${
        !myMaxWidth && !minimizeShowBar && "open_left_navbar"
      } mark`}
      onClick={(e) => {
        if (
          (e.target as HTMLElement).classList.contains("mark") &&
          !myMaxWidth
        ) {
          setMinimizeShowBar(true);
        }
      }}
      style={{
        width: myMaxWidth ? (minimizeShowBar ? "120px" : "300px") : "100%",
      }}
    >
      <div className={`inside ${!myMaxWidth && !minimizeShowBar && "open"}`}>
        <div className="logo">
          <div className="img">
            {myMaxWidth ? minimizeShowBar ? <ShafeqLogo minimize={true}/> : <ShafeqLogo /> : <ShafeqLogo />}
          </div>
        </div>
        <div
          className="links"
          style={{
            padding: myMaxWidth ? (minimizeShowBar ? 0 : "0 10px") : "0 10px",
          }}
        >
          {myNormalLink.map((content, index) => (
            <NormalLink
              content={content}
              key={index}
              minimizeShowBar={minimizeShowBar}
              myMaxWidth={myMaxWidth}
            />
          ))}
          {myOptionsLink.map((content, index) => (
            <OptionsLink
              content={content}
              key={index}
              minimizeShowBar={minimizeShowBar}
              setMinimizeShowBar={setMinimizeShowBar}
              myMaxWidth={myMaxWidth}
            />
          ))}
        </div>
        <div className="logoutBtn" onClick={() => logout()}>
          {myMaxWidth ? !minimizeShowBar ? "Logout" : <BiLogOut /> : "Logout"}
        </div>
      </div>
    </div>
  );
};

export default LeftSideNavbar;
