export const Titles = [
  {
    title: "ID",
    orderFunctionality: false,
  },
  {
    title: "Image",
    orderFunctionality: false,
  },
  {
    title: "Slug",
    orderFunctionality: false,
  },
  {
    title: "color Palette",
    orderFunctionality: false,
  },
  {
    title: "B.G Color",
    orderFunctionality: false,
  },
  {
    title: "tags",
    orderFunctionality: false,
  },
  {
    title: "Order",
    orderFunctionality: false,
  },
  {
    title: "Featured",
    orderFunctionality: false,
  },
  {
    title: "Active",
    orderFunctionality: false,
  },
];
