import React, { useState, useCallback } from 'react';
import { HexColorPicker } from 'react-colorful';

const ColorPickerWithInput = React.forwardRef<HTMLInputElement, any>(
  ({ color, onChange, onAdd }, ref) => {
    const [inputValue, setInputValue] = useState(color);

    const handleColorChange = useCallback(
      (newColor: string) => {
        onChange(newColor);
        setInputValue(newColor);
      },
      [onChange]
    );

    const handleInputChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setInputValue(newValue);
        if (/^#[0-9A-F]{6}$/i.test(newValue)) {
          onChange(newValue);
        }
      },
      [onChange]
    );

    const handleAddColor = useCallback(() => {
      if (/^#[0-9A-F]{6}$/i.test(inputValue)) {
        onAdd(inputValue);
      }
    }, [inputValue, onAdd]);

    return (
      <div className="color-picker-with-input">
        <HexColorPicker color={color} onChange={handleColorChange} />
        <div className="color-input-form">
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            // pattern="^#[0-9A-F]{6}$"
            title="Hex color code (e.g., #FF0000)"
            className="color-input"
            ref={ref}
          />
          <button
            type="button"
            onClick={handleAddColor}
            className="add-color-btn"
          >
            Add Color
          </button>
        </div>
      </div>
    );
  }
);

export const MultipleColorPicker = React.forwardRef<HTMLInputElement, any>(
  ({ value = [], onChange, max = 5 }, ref) => {
    const [currentColor, setCurrentColor] = useState('#129145');

    const addColor = useCallback(
      (newColor: string) => {
        if (value.length < max) {
          onChange([...value, newColor]);
          setCurrentColor('#129145');          
        }
      },
      [value, onChange, max]
    );

    const removeColor = useCallback(
      (colorToRemove: string) => {
        onChange(value.filter((c: string) => c !== colorToRemove));
      },
      [value, onChange]
    );

    return (
      <div className="multiple-color-picker">
        <div className="selected-colors">
          {value.map((c: string, i: number) => (
            <div key={i} className="color-item">
              <div className="color-preview" style={{ backgroundColor: c }} />
              <span className="color-hex">{c}</span>
              <button
                type="button"
                onClick={() => removeColor(c)}
                className="remove-color"
                aria-label={`Remove color ${c}`}
              >
                ×
              </button>
            </div>
          ))}
        </div>
        {value.length < max && (
          <ColorPickerWithInput
            color={currentColor}
            onChange={setCurrentColor}
            onAdd={addColor}
            ref={ref}
          />
        )}
      </div>
    );
  }
);