import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useAuth } from "../../context/authContext";
import { ShafeqLogo } from "../../assets/images/_index";
import "../../style/login.css";
import myAuroraGift from "../../assets/images/gif/aurora (2).gif";
import { LoginForm } from "./components/_index";
import { StartLoader } from "../../components/startLoader";

interface LoginFormData {
  email: string;
  password: string;
}

const LoginPage: React.FC = () => {
  const [isPassword, setIsPassword] = useState(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<LoginFormData>();
  const { login, isAuthenticated, isLoading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  useEffect(()=>{
    document.title = "Login";
  },[])

  const onSubmit = async (data: LoginFormData) => {
    const loadingToast = toast.loading("Signing in...");
    try {
      await login(data.email, data.password);
      toast.dismiss(loadingToast);
      toast.success("Signed in successfully!");
      navigate("/");
    } catch (error: any) {
      toast.dismiss(loadingToast);
      if (error.response?.data?.errors) {
        Object.entries(error.response.data.errors).forEach(([key, value]) => {
          setError(key as keyof LoginFormData, {
            type: "manual",
            message: Array.isArray(value) ? value[0] : (value as string),
          });
        });
        toast.error("Please check your input and try again.");
      } else {
        console.error(error);
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };

  if (isLoading) {
    return <StartLoader />;
  }
  return (
    <main className="loginPage">
      <LoginForm
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        register={register}
        errors={errors}
        isPassword={isPassword}
        setIsPassword={setIsPassword}
      />

      <div className="right">
        <div className="background">
          <img src={myAuroraGift} alt="Login background" />
        </div>
        <div className="logo">
          <ShafeqLogo />
        </div>
        <div className="message">
          Shafaq App Dashboard <br />
          <div
            style={{ fontWeight: "600", marginTop: "1rem", fontSize: "1.2vw" }}
          >
            info@Shafaq.sa
          </div>
        </div>
      </div>
    </main>
  );
};

export default LoginPage;
