import  "../../style/tables.css";

const arr = [0, 1, 2, 3, 4, 5];
export const Loader: React.FC = () => {
  return (
    <div className="loader">
      {arr.map((i) => {
        return (
          <div className="row" key={i}>
            <div className="sq" style={generateStyle()}>
              <div className="load"></div>
            </div>
            <div className="circle_load" style={generateStyle()}>
              <div className="load"></div>
            </div>
            <div className="line_load" style={generateStyle()}>
              <div className="load"></div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const generateStyle = () => {
  const order = Math.floor(Math.random() * arr.length);
  return {
    order: order,
  };
};
