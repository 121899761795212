import { Toaster } from "react-hot-toast";
import { Outlet } from "react-router-dom";
import { AuthProvider } from "../context/authContext";
import { useWindowSize } from "../hooks/useWindowSize";

const RootLayout: React.FC = () => {
  const { width } = useWindowSize();
  return (
    <AuthProvider>
      <main>
        <Toaster
          position={width > 768 ? "top-right" : "top-center"}
          reverseOrder={false}
        />
        <Outlet />
      </main>
    </AuthProvider>
  );
};

export default RootLayout;
