import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { MdUpdate } from "react-icons/md";
import { FcCancel, FcOk } from "react-icons/fc";
import { ConfirmDeleteItem } from "../../../../components/_index";
import { FaLinkedin } from "react-icons/fa";

const Row: React.FC<any> = ({
  values: [
    ShowUpdateSection,
    details,
    index,
    checkboxArr,
    setCheckboxArr,
    setFetchData,
  ],
}: any) => {
  const checkbox = (e: any) => {
    if (e.target.checked) {
      setCheckboxArr((prev: any) => [...prev, id]);
    } else {
      setCheckboxArr((prev: any) => prev.filter((elem: any) => elem !== id));
    }
  };
  const baseUrl = process.env.REACT_APP_API_URL + "storage/";
  const { id, name, bio, position, image_path, active, order, linkedin_url } =
    details;

  return (
    <tr
      className={`${index % 2 > 0 && "cd"} 
        ${checkboxArr.includes(id) && "checked"}
        `}
    >
      <td>
        <input
          className={"checkbox"}
          type="checkbox"
          onChange={checkbox}
          checked={checkboxArr.includes(id) ? true : false}
        />
      </td>
      {/* <td>{index + 1}</td> */}
      <td>{id}</td>
      <td className={"img_td"}>
        <div className={"img"}>
          <img src={baseUrl + image_path} alt="img" />
        </div>
      </td>
      <td className="sym sym_align">
        {linkedin_url ? (
          <a
            href={linkedin_url}
            className="linkedin"
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedin />
          </a>
        ) : (
          <FcCancel />
        )}
      </td>
      <td>{name}</td>
      <td>
        <div className="loc">{position}</div>
      </td>
      <td style={{ whiteSpace: "nowrap" }}>
        {bio.length > 10 ? `${bio.slice(0, 10)}...` : bio}
      </td>

      <td>
        <div className="bur">{order}</div>
      </td>

      <td className={`sym sym_align`}>
        {Number(active) ? <FcOk /> : <FcCancel />}
      </td>
      <td className={"rowActions"}>
        <div className={"update"}>
          <MdUpdate
            className={"update_in"}
            onClick={() => {
              ShowUpdateSection(id);
            }}
          />
        </div>

        <div
          className={`delete ${checkboxArr.length > 0 && "delete_in_hide"} `}
        >
          <AiOutlineDelete
            className={"delete_in"}
            onClick={() =>
              ConfirmDeleteItem(id, setFetchData, setCheckboxArr, "employee")
            }
          />
        </div>
      </td>
    </tr>
  );
};

export default Row;
