import React from "react";
import { BiError } from "react-icons/bi";

export const DescriptionInput: React.FC<any> = ({
  name,
  label,
  register,
  errors,
}) => (
  <div className="input_box">
    <div className="input_title">{label}*</div>
    <textarea
      rows={7}
      placeholder="EX..."
      {...register(name, { required: "This is Required" })}
    />
    {errors[name] && (
      <div className="validate">
        <div className="sym">
          <BiError />
        </div>
        {errors[name].message}
      </div>
    )}
  </div>
);
