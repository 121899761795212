// File: AddForm.tsx
import React from "react";
import { useForm } from "react-hook-form";
import { EmployeeService } from "../../../../../services/_index";
import {
  ImageUploader,
  TextInput,
  DescriptionInput,
  OrderInput,
  RadioInput,
  WebsiteUrlInput,
} from "../../../../../components/_index";
import { formFields } from "../constants/formFields";
const AddForm: React.FC<{ hiddenAddSection: any }> = ({ hiddenAddSection }) => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    shouldFocusError: true,
  });

  const handleAddEmployee = async (data: any) => {
    try {
      await EmployeeService.createEmployee(data);
      hiddenAddSection(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form onSubmit={handleSubmit((data) => handleAddEmployee(data))}>
      <div className="inside_form">
        <ImageUploader
          name="image_path"
          label="Card Image"
          register={register}
          setValue={setValue}
          errors={errors}
        />

        {formFields.map((field) => (
          <TextInput
            key={field.name}
            {...field}
            register={register}
            errors={errors}
          />
        ))}

        <WebsiteUrlInput
          register={register}
          errors={errors}
          name="linkedin_url"
          label="LinkedIn URL"
        />

        <DescriptionInput
          register={register}
          errors={errors}
          name="bio"
          label="Employee Bio"
        />
        <OrderInput register={register} errors={errors} />

        <RadioInput
          name="active"
          label="Active"
          options={[
            { value: "0", label: "No" },
            { value: "1", label: "Yes", isDefault: true },
          ]}
          register={register}
        />
      </div>

      <div className="actions">
        <div className="discard" onClick={hiddenAddSection}>
          Discard
        </div>
        <button type="submit" className="submit">
          Submit
        </button>
      </div>
    </form>
  );
};

export default AddForm;
