import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TagService } from "../../../../../services/_index";
import { TextInput } from "../../../../../components/_index";
import { formFields } from "../constants/formFields";
import resetFormData from "./resetFormData";

const UpdateForm: React.FC<any> = ({ hiddenUpdateSection, details }) => {
  const [resetImages, setResetImages] = useState(false);
  const {
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    shouldFocusError: true,
  });

  const resetMydata = () => {
    resetFormData(details, reset);
  };

  useEffect(() => {
    resetMydata();
  }, [details, reset, setValue]);

  const handleReset = () => {
    resetMydata();
  };

  useEffect(() => {
    if (resetImages) {
      setResetImages(false);
    }
  }, [resetImages]);

  const handleUpdateTag = async (id: any, data: any) => {
    try {
      await TagService.updateTag(id, data, details);
      hiddenUpdateSection(true);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <form
      onSubmit={handleSubmit((data) => handleUpdateTag(details.id, data))}
    >
      <div className="inside_form">
        {formFields.map((field) => (
          <TextInput
            key={field.name}
            {...field}
            register={register}
            errors={errors}
          />
        ))}
      </div>

      <div className="actions">
        <div className="discard" onClick={hiddenUpdateSection}>
          Discard
        </div>
        <div className={"reset"} onClick={handleReset}>
          Reset
        </div>
        <button type="submit" className="submit">
          Submit
        </button>
      </div>
    </form>
  );
};

export default UpdateForm;
