export const formFields = [
  {
    name: "slug",
    label: "Slug",
    type: "text",
    placeholder: "example-project",
  },
  {
    name: "title",
    label: "Title",
    type: "text",
    placeholder: "Project Title",
  },
  {
    name: "subtitle",
    label: "Subtitle",
    type: "text",
    placeholder: "Project Subtitle",
  },

  {
    name: "framework",
    label: "Framework",
    type: "text",
    placeholder: "React, Vue, etc.",
    isRequired: false,
  },
  {
    name: "programming_language",
    label: "Programming Language",
    type: "text",
    placeholder: "JavaScript, Python, etc.",
    isRequired: false,
  },
  {
    name: "backend_system",
    label: "Backend System",
    type: "text",
    placeholder: "Node.js, Django, etc.",
    isRequired: false,
  },
];
