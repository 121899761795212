import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { BiError } from "react-icons/bi";
import myBackground from "../../../assets/images/background/bg2-new.jpg";
export const BackgroundColorInput: React.FC<any> = ({
  control,
  errors,
  myDefaultValue = 0,
}) => {
  const [myColorRange, setMyColorRange] = useState(myDefaultValue);

  return (
    <div className="input_box">
      <div className="input_title">Background Color</div>
      <Controller
        name="ring"
        control={control}
        defaultValue={0}
        rules={{ required: "Ring value is required" }}
        render={({ field }) => (
          <div className="ring-range-container">
            <input
              type="range"
              min="0"
              max="360"
              {...field}
              onChange={(e) => {
                field.onChange(parseInt(e.target.value));
                setMyColorRange(parseInt(e.target.value));
              }}
              className="ring-range-input"
            />
            <div className="ring-value-display">{field.value}°</div>
          </div>
        )}
      />
      <img
        src={myBackground}
        alt="img"
        style={{ filter: `hue-rotate(${myColorRange}deg)` }}
      />
      {errors.ring && (
        <div className="validate">
          <div className="sym">
            <BiError />
          </div>
          {errors.ring.message}
        </div>
      )}
    </div>
  );
};
