import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { MdUpdate } from "react-icons/md";
import { FcCancel, FcOk } from "react-icons/fc";
// import { DeleteProject } from "./DeleteItem";
import myBackground from "../../../../assets/images/background/bg2-new.jpg";
import { ConfirmDeleteItem } from "../../../../components/_index";

const Row: React.FC<any> = ({
  values: [
    ShowUpdateSection,
    details,
    index,
    checkboxArr,
    setCheckboxArr,
    setFetchData,
  ],
}: // ShowUpdateSection,
// DeleteProject,

any) => {
  const checkbox = (e: any) => {
    if (e.target.checked) {
      setCheckboxArr((prev: any) => [...prev, id]);
    } else {
      setCheckboxArr((prev: any) => prev.filter((elem: any) => elem !== id));
    }
  };
  const baseUrl = process.env.REACT_APP_API_URL + "storage/";
  const {
    id,
    title,
    description,
    website_url,
    color_palette,
    key_features,
    framework,
    programming_language,
    backend_system,
    detail_image_1_path,
    detail_image_2_path,
    is_featured,
    order,
    tags,
    subtitle,
    card_image_path,
    // project_id,
    slug,
    active,
  } = details;

  const myColors = JSON.parse(color_palette);
  const { ring } = myColors;

  return (
    <tr
      className={`${index % 2 > 0 && "cd"} 
        ${checkboxArr.includes(id) && "checked"}
        `}
    >
      <td>
        <input
          className={"checkbox"}
          type="checkbox"
          onChange={checkbox}
          checked={checkboxArr.includes(id) ? true : false}
        />
      </td>
      <td>{id}</td>
      <td className={"img_td"}>
        <div className={"img"}>
          <img src={baseUrl + card_image_path} alt="img" />
        </div>
      </td>
      <td>{slug}</td>
      <td>{title}</td>
      <td>{subtitle}</td>
      <td style={{ whiteSpace: "nowrap" }}>
        {description.length > 10
          ? `${description.slice(0, 10)}...`
          : description}
      </td>
      <td className={`sym sym_align`}>
        {website_url ? (
          <div className={"loc"}> {website_url}</div>
        ) : (
          <FcCancel />
        )}
      </td>
      <td>
        <div className="log">
          {key_features ? key_features.split(",").length : 0}
        </div>
      </td>
      <td>
        <div className="color-palette">
          {Object.entries(myColors)
            .filter(([key]) => key !== "ring")
            .map(([_, color]: any, index) => (
              <span
                key={index}
                className="my-color"
                style={{ backgroundColor: color }}
              ></span>
            ))}
        </div>
      </td>
      <td className={"img_td"}>
        <div className={"img"}>
          <img
            src={myBackground}
            alt="background"
            style={{ filter: `hue-rotate(${ring}deg)`, objectFit: "cover" }}
          />
        </div>
      </td>
      <td className={`sym sym_align`}>
        {framework ? framework : <FcCancel />}
      </td>
      <td className={`sym sym_align`}>
        {programming_language ? programming_language : <FcCancel />}
      </td>
      <td className={`sym sym_align`}>
        {backend_system ? backend_system : <FcCancel />}
      </td>
      <td>
        <div className={"cat"}>{tags.length}</div>
      </td>
      <td>
        <div className="bur">{order}</div>
      </td>
      <td className={"img_td"}>
        <div className={"img"}>
          <img src={baseUrl + detail_image_1_path} alt="img" />
        </div>
      </td>
      <td className={"img_td"}>
        <div className={"img"}>
          <img src={baseUrl + detail_image_2_path} alt="img" />
        </div>
      </td>
      <td className={`sym sym_align`}>
        {Number(is_featured) ? <FcOk /> : <FcCancel />}
      </td>
      <td className={`sym sym_align`}>
        {Number(active) ? <FcOk /> : <FcCancel />}
      </td>
      <td className={"rowActions"}>
        <div className={"update"}>
          <MdUpdate
            className={"update_in"}
            onClick={() => {
              ShowUpdateSection(id);
            }}
          />
        </div>

        <div
          className={`delete ${checkboxArr.length > 0 && "delete_in_hide"} `}
        >
          <AiOutlineDelete
            className={"delete_in"}
            onClick={() =>
              ConfirmDeleteItem(id, setFetchData, setCheckboxArr, "project")
            }
          />
        </div>
      </td>
    </tr>
  );
};

export default Row;
