import React from "react";
import {
  BsSortAlphaDown,
  BsSortAlphaDownAlt,
  BsSortNumericDown,
  BsSortNumericDownAlt,
} from "react-icons/bs";

interface SortIndicatorProps {
  desc_asc: boolean;
  iSsortSymNum: boolean;
}

export const SortIndicator: React.FC<SortIndicatorProps> = ({
  desc_asc,
  iSsortSymNum,
}) => {
  return (
    <div className="sym">
      {!desc_asc ? (
        iSsortSymNum ? (
          <BsSortNumericDownAlt />
        ) : (
          <BsSortAlphaDownAlt />
        )
      ) : iSsortSymNum ? (
        <BsSortNumericDown />
      ) : (
        <BsSortAlphaDown />
      )}
    </div>
  );
};
