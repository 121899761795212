import React from "react";
import { FaSort } from "react-icons/fa";
import { Loader } from "../../components/_index";

export const Table: React.FC<any> = ({ myProps }) => {
  const {
    items,
    isLoad,
    ShowUpdateSection,
    checkboxArr,
    setCheckboxArr,
    setOrderQuery,
    setDesc_asc,
    setISsortSymNum,
    setFetchData,
    setSortName,
    Titles,
    Row,
  } = myProps;
  const handleSort = (dbName: any, isNumber: any, sortName: any) => {
    if (items.data.length > 0) {
      setOrderQuery(dbName);
      setDesc_asc((prev: any) => !prev);
      setISsortSymNum(isNumber);
      setSortName(sortName);
    }
  };
  return (
    <div className="table">
      <table>
        <tbody>
          <tr>
            <th className="checkbox"></th>
            {Titles.map(
              (
                {
                  title,
                  orderFunctionality,
                  dbName,
                  isNumber,
                  OrderTitle,
                }: any,
                index: any
              ) => (
                <React.Fragment key={index}>
                  {!orderFunctionality ? (
                    <th>{title}</th>
                  ) : (
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSort(dbName, isNumber, OrderTitle)}
                    >
                      {title}
                      <div className="sym">
                        <FaSort />
                      </div>
                    </th>
                  )}
                </React.Fragment>
              )
            )}
          </tr>
          {!isLoad &&
            items.data.map((details: any, index: any) => (
              <Row
                key={index}
                values={[
                  ShowUpdateSection,
                  details,
                  index,
                  checkboxArr,
                  setCheckboxArr,
                  setFetchData,
                ]}
              />
            ))}
        </tbody>
      </table>
      {isLoad && <Loader />}
    </div>
  );
};
