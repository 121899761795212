import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { AiFillHome, AiOutlineHome, AiOutlineUser } from "react-icons/ai";
import { RiMenuFoldLine, RiMenuUnfoldLine } from "react-icons/ri";
import { SlSizeActual, SlSizeFullscreen } from "react-icons/sl";
import { useWindowSize } from "../../../hooks/_index";
import { useGlobalContext } from "../../../context/globalContext";

const TopSideNavbar: React.FC<any> = ({
  minimizeShowBar,
  setMinimizeShowBar,
}: any) => {
  const location = useLocation();
  const { width } = useWindowSize();
  const { myPath } = useGlobalContext();
  const [isFullScrenn, setIsFullScrenn] = useState(false);
  const toggleFullscreen = () => {
    if (document.fullscreenEnabled) {
      if (!document.fullscreenElement) {
        document.documentElement
          .requestFullscreen()
          .then(() => setIsFullScrenn(true))
          .catch((err) => console.error(`Failed to enter fullscreen: ${err}`));
      } else {
        document
          .exitFullscreen()
          .then(() => setIsFullScrenn(false))
          .catch((err) => console.error(`Failed to exit fullscreen: ${err}`));
      }
    } else {
      console.warn("Fullscreen is not enabled on this browser.");
    }
  };

  return (
    <div
      className={"top"}
      style={{
        paddingLeft:
          width > 1024 ? (!minimizeShowBar ? "310px" : "120px") : "10px",
      }}
    >
      <div className={"background"}>
        <div className={"page_name"}>
          <div className={"mini"}>Pages / {myPath}</div>
          <div className={"big"}>{myPath}</div>
        </div>
        <div className={"buttons"}>
          <button className={"size"} onClick={toggleFullscreen}>
            {isFullScrenn ? <SlSizeActual /> : <SlSizeFullscreen />}
          </button>
          <button
            className={"menu"}
            onClick={() => setMinimizeShowBar((prev: any) => !prev)}
          >
            {minimizeShowBar ? <RiMenuUnfoldLine /> : <RiMenuFoldLine />}
          </button>
          <Link to={"/"} className={"home"}>
            {location.pathname === "/" ? <AiFillHome /> : <AiOutlineHome />}
          </Link>
          {/* <button className={"theme"}>
              <MdOutlineDarkMode />
            </button> */}
          <button className={"profile"}>
            <AiOutlineUser />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TopSideNavbar;
